#screen-component {
  height: 100vh;
  overflow: hidden;
  box-shadow: none !important;
  outline: none !important;
  .flex-overflow { min-height: 0px; } /* need for non-chrome browsers */

  font-family: 'proxima-nova', sans-serif;
  font-weight: 400;
  font-style: normal;

  #display-header {
    -webkit-animation: Gradient 60s ease infinite;
    -moz-animation: Gradient 60s ease infinite;
    animation: Gradient 60s ease infinite;
    background: linear-gradient(-45deg, #fff, #eee, #fff, #eee);
    background-size: 250% 100%;
    color: #002855;
    font-size: 180%;
    font-weight: 500;
    line-height: 1.3;
    border-bottom: 12px solid #daaa00;

    .left > span { font-size: 120%; margin-top: 24px; display: inline-block; }

    .left > img {
      padding: 18px 52px 18px 18px;
      margin-right: 48px;
      margin-bottom: 12px;
      max-height: 80px;
      vertical-align: middle;
      border-right: 2px solid #002855;
    }

    .display-time { display: block; font-size: 85%; margin-top: 18px; }
    .display-date { display: block; font-size: 70%; }
  }

  #display-body {
    padding: 0 16px 20px;
    color: white;
    background-color: #002855;
    font-size: 85%;

    hr { margin: 9px; border: none; height: 1px; background-color: #03437c; }
    p.header { margin: 0 0 .15rem; font-weight: 600; }
    p.listing { margin-bottom: .15rem; padding-left: 1.5rem; }

    table { font-size: 105%; line-height: 2.2rem; }
    table th:nth-child(2), table td:nth-child(2) { text-align: right; }
    table td { padding: .15rem; }
    table tr:not(:last-child) { border-bottom: 1px solid #03437c; }
  }



  /* gradient animations: https://codepen.io/JanickFischr/pen/BYvVqP */
  @-webkit-keyframes Gradient {
    0%   { background-position: 0% 50% }
    50%  { background-position: 100% 50% }
    100% { background-position: 0% 50% }
  }

  @-moz-keyframes Gradient {
    0%   { background-position: 0% 50% }
    50%  { background-position: 100% 50% }
    100% { background-position: 0% 50% }
  }

  @keyframes Gradient {
    0%   { background-position: 0% 50% }
    50%  { background-position: 100% 50% }
    100% { background-position: 0% 50% }
  }
}
