#loading-component {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .logo {
    display: block;
    margin: auto;
    margin-top: -100px;
    margin-bottom: 24px;
    width: 350px;
  }
}
